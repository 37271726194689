var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"formStaff"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Tên đăng nhập","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.staff.username),callback:function ($$v) {_vm.$set(_vm.staff, "username", $$v)},expression:"staff.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Mật khẩu","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password"},model:{value:(_vm.staff.password),callback:function ($$v) {_vm.$set(_vm.staff, "password", $$v)},expression:"staff.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Họ tên","label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":"Fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name"},model:{value:(_vm.staff.fullName),callback:function ($$v) {_vm.$set(_vm.staff, "fullName", $$v)},expression:"staff.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email"},model:{value:(_vm.staff.email),callback:function ($$v) {_vm.$set(_vm.staff, "email", $$v)},expression:"staff.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Đội","label-for":"full-name"}},[_c('v-select',{staticClass:"w-100",attrs:{"label":"name","reduce":function (item) { return item.id; },"options":_vm.teamOptions,"placeholder":"Chọn đội"},model:{value:(_vm.staff.teamId),callback:function ($$v) {_vm.$set(_vm.staff, "teamId", $$v)},expression:"staff.teamId"}})],1)],1)],1),_c('b-row',{staticClass:"mt-2 justify-content-center"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"outline-secondary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"to":{ name: _vm.constRouter.USER_STAFF.name }}},[_vm._v(" Hủy ")]),_c('b-button',{attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Thêm ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }