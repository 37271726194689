<template>
  <b-card>
    <div>
      <!-- User Info: Input Fields -->
      <validation-observer ref="formStaff">
        <b-form @submit.prevent="handleSubmit">
          <!-- Header: Personal Info -->

          <b-row class="mt-1">
            <!-- Field: Username -->
            <b-col cols="12" md="4">
              <b-form-group label="Tên đăng nhập" label-for="username">
                <validation-provider #default="{ errors }" name="username" rules="required">
                  <b-form-input id="username" v-model="staff.username" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Username -->
            <b-col cols="12" md="4">
              <b-form-group label="Mật khẩu" label-for="password">
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="staff.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Full Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Họ tên" label-for="full-name">
                <validation-provider #default="{ errors }" name="Fullname" rules="required">
                  <b-form-input id="full-name" v-model="staff.fullName" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <!-- Field: Email -->
            <b-col cols="12" md="4">
              <b-form-group label="Email" label-for="full-name">
                <validation-provider #default="{ errors }" name="email">
                  <b-form-input id="email" v-model="staff.email" type="email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Đội" label-for="full-name">
                <v-select
                  v-model="staff.teamId"
                  label="name"
                  :reduce="(item) => item.id"
                  :options="teamOptions"
                  placeholder="Chọn đội"
                  class="w-100"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 justify-content-center">
            <!-- Action Buttons -->
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: constRouter.USER_STAFF.name }"
            >
              Hủy
            </b-button>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Thêm
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
  BCard,
  BAvatar,
  BMedia,
  BInputGroupAppend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { avatarText } from '@core/utils/filter';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import constRouter from '@/constants/constRouter';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { create, teamList } from '@/apis/apiStaff';
import vSelect from 'vue-select';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    BCard,
    BAvatar,
    BMedia,
    BInputGroupAppend,

    flatPickr,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const genderOptions = [
      { text: 'Nam', value: 'male' },
      { text: 'Nữ', value: 'female' },
    ];

    const avatar = ref(null);

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      avatar.value = base64;
    });

    return {
      avatar,
      avatarText,
      genderOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      constRouter,
    };
  },
  data: () => ({
    required,
    staff: {
      username: undefined,
      password: undefined,
      fullName: undefined,
      phoneNumber: undefined,
      isSuperAdmin: false,
      isActive: 1,
      birthDay: undefined,
      email: undefined,
      gender: undefined,
      facebook: undefined,
      address: undefined,
      groupId: undefined,
      teamId: undefined,
    },
    teamOptions: [],
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  created() {
    this.fetchTeams();
  },
  methods: {
    fetchTeams() {
      teamList().then((res) => {
        this.teamOptions = res.data.data;
      });
    },
    handleSubmit() {
      const form = this.$refs.formStaff;
      form.validate().then((success) => {
        if (success) {
          const payload = {
            username: this.staff.username,
            password: this.staff.password,
            name: this.staff.fullName,
            // phoneNumber: this.staff.phoneNumber,
            // is_superadmin: this.staff.isSuperAdmin,
            // isActive: this.staff.isActive,
            // birthDay: this.staff.birthDay,
            email: this.staff.email,
            // gender: this.staff.gender,
            // facebook: this.staff.facebook,
            // address: this.staff.address,
            // groupId: this.staff.groupId,
            team_id: this.staff.teamId,
          };

          // const { avatar } = this;
          //
          // const formData = new FormData();
          // Object.keys(this.staff).forEach((key) => {
          //   if (
          //     !(this.staff[key] === undefined || this.staff[key] === null || this.staff[key] === '')
          //   ) {
          //     formData.append(key, this.staff[key]);
          //   }
          // });
          // formData.append('groupId', '93c00307-2dc2-4563-8fc0-79a7d6f0fb0a');
          // formData.append('avatar', avatar);

          // fake group id
          create(payload)
            .then((res) => {
              const { data } = res.data;
              this.$router
                .push({ name: constRouter.USER_STAFF_DETAIL.name, params: { id: data?.id } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Thêm nhân viên',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'Thêm nhân viên thành công!',
                    },
                  });
                });
            })
            .catch((err) => {
              if (err.response.status === 422) {
                form.setErrors(err.response.data.errors);
              }
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Thêm nhân viên',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Thêm nhân viên thất bại!',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
